<template>
  <div>
    <changeStatus v-if="data.status !== 'cancellation'" :data="data" :saveCalendar="function () { saveCalendar() }" />
    <changeDate :data="data" :saveCalendar="function () { hint = true; saveCalendar() }" />
    <deleteAll :data="data" :saveCalendar="function () { saveCalendar() }"/>
    <transition name="flixFadeIn">
      <div :key="success">
        <div class="flix-form-group">
          <successMsg v-if="success" />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    changeDate () { return import('@/components/bookingInfoPage/edit/changeDate.vue') },
    changeStatus () { return import('@/components/bookingInfoPage/edit/changeStatus.vue') },
    successMsg () { return import('@/components/bookingInfoPage/edit/success.vue') },
    deleteAll () { return import('@/components/bookingInfoPage/edit/delete.vue') }
  },
  props: {
    data: Object
  },
  data () {
    return {
      open: false,
      success: false,
      hint: false,
      user: this.$route.params.user
    }
  },
  methods: {
    saveCalendar () {
      this.success = true
      this.reloadPage()
    },
    reloadPage () {
      setTimeout(function () {
        this.$router.go({ name: this.$route.name, params: this.$route.params })
      }.bind(this), 6000)
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
